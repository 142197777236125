
































import Vue from "vue";
export default Vue.extend({
	name: "Login",
	data() {
		return {
			email: "",
			password: "",

			errorAlert: ""
		};
	},
	methods: {
		login() {
			this.$store.commit("pushLoading", {
				name: "LOGIN",
				message: "로그인 시도 중"
			});
			this.$store
				.dispatch("LOGIN", {
					email: this.email,
					password: this.password
				})
				.then(token => {
					this.$store
						.dispatch("GET_USER_PROFILE", {
							token,
							pushSubscription: localStorage.getItem("circles.pushSubscription")
						})
						.then(user => {
							localStorage.setItem("circles.loginToken", token);
							this.$store.commit("clearLoading", "LOGIN");
							this.$router.push("/");
						})
						.catch(err => {
							this.$store.commit("clearLoading", "LOGIN");
							this.errorAlert = err.response.data.message || "서버 에러";
						});
				})
				.catch(err => {
					this.$store.commit("clearLoading", "LOGIN");
					this.errorAlert = err.response.data.message || "서버 에러";
				});
		}
	}
});
